import anime from 'animejs/lib/anime.es';

var stopScrollCalled = 0;
/* -----main menu back---------------------------------------------------------------- */
function callGallery() {
    // Scroll the page
    scrollPage();
    // Lazy loads images then checks position in viewport
    if (!!window.IntersectionObserver) {
        let observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    console.log(entry);
                    console.log(entry.target.dataset.id);
                    entry.target.src = entry.target.dataset.src;
                    observer.unobserve(entry.target);
                }
            });
        }, {
            rootMargin: '100px 0px 100px 0px'
        });
        document.querySelectorAll('img').forEach(img => {
            observer.observe(img);
        })

        let observer2 = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    $('.gallery-link').removeClass('highlight');
                    $('.gallery-thumb').removeClass('in-view');
                    var element = document.body.querySelector('.gallery-link[data-id="' + entry.target.dataset.id + '"]');

                    element.classList.add('highlight');
                    entry.target.classList.add('in-view');
                    // observer.unobserve(entry.target);
                }
            });
        }, {
            rootMargin: '-20%',
            threshold: 0.5
        });
        document.querySelectorAll('img').forEach(img => {
            // observer.observe(img);
            observer2.observe(img)
        });
        //  galleryCalled = 1;
    } else {
        //alert('js not working');
        $('img').each(function() {
            $(this).attr('src', $(this).attr('data-src'));
            $(this).addClass('in-view');
        })
    }
    $('body').on('click touchstart mousewheel', '#scroll-btn', function(e) {
        stopScroll();
        $('#scroll-btn').hide();
    });


    // Add links to titles referencing gallery
    $(document).on('click', '.gallery-link, .gallery-thumb', function(e) {
        //adding off to remove mutliple event handlers
        if (stopScrollCalled == 1) {
            loadGalleryContent('gallery', $(this).data('id'), $(this).data('slug'));
        }
    });


}

function callAbout() {


    if (!!window.IntersectionObserver) {
        let news = document.getElementById('news-block');
        let indicator = document.getElementById('news-indicator');
        let observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    indicator.classList.add('opacity-0', 'height-0');
                    //  observer.unobserve(entry.target);
                } else {
                    indicator.classList.remove('opacity-0', 'height-0');
                }
            });
        }, { rootMargin: "0px 0px 30px 0px" });
        observer.observe(news);
    } else document.querySelector('#warning').style.display = 'block';
}

var homecount = 0;

function callHome() {


    homecount++;
    if (homecount == 1) {
        var vid = document.getElementById("myVideo");
        var timeline = anime.timeline();
        vid.play();
        $('body').addClass('home');
        vid.onended = function() {
            var element = document.getElementById("hero-text");
            element.classList.add("opacity-100");
            timeline

                .add({
                    targets: '#hero-text',
                    opacity: 1,
                    duration: 500,
                    easing: 'easeInExpo'
                })
                .add({
                    delay: 500,
                    targets: '#nav-main',
                    opacity: 1,
                    duration: 100,
                    easing: 'easeInExpo'
                })
                .add({
                    targets: '.icon-instagram',
                    delay: 800,
                    opacity: 1,
                    duration: 500,
                    easing: 'easeInExpo'
                })
                .add({
                    targets: '.icon-twitter-with-circle',
                    opacity: 1,
                    duration: 500,
                    easing: 'easeInExpo'
                })

        }

    } else {
        $('#myVideo').addClass('hidden');
        $('#home-gfx').removeClass('hidden');
        $('#hero-text').removeClass('opacity-0');
        $('.icon-instagram').removeClass('opacity-0');
        $('.icon-twitter-with-circle').removeClass('opacity-0');
    }
}

function callProject() {
    stopScroll();
    //This is called for the chosen gallery page 
    let galleryImages = document.getElementById('gallery-images');
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    // Check if intersection observer works and vieport width is greater than 640px 
    if (!!window.IntersectionObserver && vw > 640) {
        var images = document.getElementById('gallery-images').querySelectorAll('img');
        let firstImage = images[0];
        let info = document.getElementById('project-info');


        let observer3 = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    info.classList.remove('opacity-0');
                    galleryImages.classList.add('opacity-75');
                    galleryImages.classList.remove('opacity-0');
                } else {
                    info.classList.add('opacity-0');
                    galleryImages.classList.remove('opacity-75');
                }
            })
        }, { threshold: .75 });
        observer3.observe(firstImage);
    } else {
        galleryImages.classList.remove('opacity-0');
    }
}



/* -------------------------------------------------------------------------------------- */
function setPageTitle(string) {
    $('title').html(string);
}
/* -------------------------------------------------------------------------------------- */
function loadGalleryContent(url, gallery_id, gallery_slug) {
    $('#loading').show();
    $.ajax({
            url: '/_includes/content.php',
            cache: false,
            type: 'POST',
            data: { cid: url, gallery_id: gallery_id, gallery_slug: gallery_slug, ajax: 1 }
        })
        .done(function(html, status, request) {
            $('main').html(html);
            setPageTitle(request.getResponseHeader('pageTitle'));
            history.pushState({ page: url }, 'New URL: /' + url + '/' + gallery_slug, '/' + url + '/' + gallery_slug);
            //  pageName = '/' + url + '/' + gallery_slug;
            callProject();
            $('html,body').scrollTop(0);

        }).fail(function() {
            alert('error');
        });
}
/* -------------------------------------------------------------------------------------- */

function loadContent(url, pop = false) {
    $('#loading').show();
    // alert('hello');
    $.ajax({
            url: '../_includes/content.php',
            cache: false,
            type: 'POST',
            data: { cid: url, ajax: 1 }
        })
        .done(function(html, status, request) {
            $('html,body').scrollTop(0);
            $('main').html(html);
            $('#loading').hide();
            setPageTitle(request.getResponseHeader('pageTitle'));

            if (pop == false) {
                history.pushState({ page: url }, 'New URL: /' + url, '/' + url);
            }

            // pageName = '/' + url;
            stopScroll();
            $('body').removeClass().addClass(url);
            switch (url) {
                case 'gallery':
                    $('#logo, #nav-main, #nav-social').css('opacity', '1');
                    callGallery();
                    break;
                case 'about':
                    $('#logo, #nav-main, #nav-social').css('opacity', '1');
                    callAbout();
                    break;
                case 'home':
                    callHome();
                    $('#logo, #nav-social').css('opacity', '0');
                    break;
                case 'contact':
                    $('#logo, #nav-main, #nav-social').css('opacity', '1');
                    break;
                default:
                    null
            }

        }).fail(function() {
            alert('error');
        });
}

/* -------------------------------------------------------------------------------------- */



$('.logo-hover').hide();
$('.section-image').hide();



$('body').on('click touchstart', '.nav-main',
    function(e) {

        var page = $(this).attr('title');

        if (page == 'instagram' || page == 'twitter') {
            switch (page) {
                case 'instagram':
                    window.open('https://instagram.com/tomscuttdesign/', '_blank')
                    break;
                case 'twitter':
                    window.open('https://twitter.com/tomscuttdesign', '_blank')
                    break;
                default:
                    null;
            }
        } else {
            // bgToggle = 1;
            loadContent(page);
        }
        e.preventDefault();
    });

/* -------------------------------------------------------------------------------------- */


var scrolltimeout;
var scrolltimeout2;
var scrollinterval;

function scrollPage() {
    $('html,body').scrollTop(0);

    var scrollHeight = $(document).height();
    var time = scrollHeight * 14;

    $('html, body').animate({ scrollTop: $(document).height() }, time, 'linear');
    scrolltimeout = setTimeout(function() {
        $('html, body').animate({ scrollTop: 0 }, time, 'linear');
    }, time);
    scrollinterval = setInterval(function() {
        // 4000 - it will take 4 secound in total from the top of the page to the bottom
        $('html, body').animate({ scrollTop: $(document).height() }, time, 'linear');
        scrolltimeout2 = setTimeout(function() {
            $('html, body').animate({ scrollTop: 0 }, time, 'linear');
        }, time);

    }, time);
}

function stopScroll() {
    $('html, body').stop();
    clearTimeout(scrolltimeout);
    clearTimeout(scrolltimeout2);
    clearInterval(scrollinterval);
    stopScrollCalled = 1;
}

/* -------------------------------------------------------------------------------------- */

function getPage() {
    var pathArray = window.location.pathname.split('/');
    var secondLevelLocation = pathArray[1];
    console.log('where are we' + secondLevelLocation);
    return secondLevelLocation;
}

$(window).on('popstate', function() {
    loadContent(getPage, true);
    //alert(getPage());
});

var thisPageTitle = getPage();
switch (thisPageTitle) {
    case 'gallery':
        loadContent('gallery');
        break;
    case 'about':
        loadContent('about');
        break;
    case 'home':
        loadContent('home');
        break;
    case 'contact':
        loadContent('contact');
        break;
    default:
        null;


}